import React from "react";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";
import { SENTENCE_CHAR_TOOLTIP_TITLE } from "../../../constants/Constants";

const SentenceBoxStats = ({ status, wpm, countDown, stats, rawKeyStrokes }) => {
  return (
    <>
      {status === "finished" && (<>
        <h3>経過時間: {countDown} s </h3>
        <Box display="flex" flexDirection="row">
          <h3>単語数/分: {Math.round(wpm)}</h3>
          <h4>
            正確率:{" "}
            {Math.round(
              (stats.correct /
                (stats.correct + stats.incorrect + stats.extra)) *
              100
            )}{" "}
            %
          </h4>
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line" }}>
                {SENTENCE_CHAR_TOOLTIP_TITLE}
              </span>
            }
          >
            <h4>
              文字数: <span className="correct-char-stats">{stats.correct}</span>/
              <span className="incorrect-char-stats">{stats.incorrect}</span>/
              <span className="incorrect-char-stats">{stats.extra}</span>
            </h4>
          </Tooltip>
          <h4>キーストローク数/分: {Math.round((rawKeyStrokes / countDown) * 60.0)}</h4>
        </Box>
      </>)}
    </>
  );
};

export default SentenceBoxStats;
