import React from "react";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";

const Logo = ({ isFocusedMode, isMusicMode }) => {
  return (
    <div className="header" style={{visibility: isFocusedMode ? 'hidden' : 'visible' }}>
      <h1>
        {/* Display an image of logo */}
        Moominous Typing <KeyboardAltIcon fontSize="large" /> <img src="./logo.png" alt="logo" height="35"/>
      </h1>
      {/* <span className="sub-header">
        an elegant typing experience, just start typing
      </span> */}
    </div>
  );
};

export default Logo;
